.section {
    min-height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    /* overflow: hidden; */
}

.section2 {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    overflow: hidden;
}

.middle {
    width: 60%;
}

.container {
    width: 100%;
    text-align: center;
    display: flex;
    gap: 50px;
}

.divider1,
.divider2 {
    flex: 1;
}

.divider1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.divider1 button {
    padding: 6px 20px;
    border-radius: 5px;
    border: 1px solid var(--border);
    background-color: white;
    cursor: pointer;
}


.container h1 {
    font-weight: 600;
}

.circle {
    width: 20px;
    height: 20px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 100px;
}

.phone,
.mail,
.location {
    width: 20px;
    fill: white;
    margin: -10px;
}

.textarea {
    resize: none;
    width: calc(100% - 40px);
    height: 100%;
    min-height: 100px;
    border-radius: 10px;
    margin: 0;
    padding: 20px;
    border: 1px solid var(--border);
    font-family: "Inter", sans-serif;
}

.textarea:focus {
    outline: none;
}

.line2 {
    height: 1px;
    width: 100%;
    background-color: var(--border);
    margin-bottom: 30px;
}

.mid {
    display: flex;
    gap: 20px;
    align-items: center;
    text-align: left;
    margin-bottom: 20px;
}

.center {
    width: 80%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.center input:focus {
    outline: none;
}

.center p {
    font-weight: 600;
    margin-bottom: 10px;
}

.signme {
    width: 300px;
    height: 40px;
    border: 1px solid var(--border);
    border-radius: 10px;
    padding-inline: 15px;
    font-size: 15px;
}


.center button {
    margin-top: 20px;
    width: fit-content;
    padding: 6px 20px;
    border-radius: 5px;
    border: 1px solid var(--border);
    background-color: white;
    cursor: pointer;
    transition: 0.1s;
    font-size: 15px;
}

.center button:hover {
    box-shadow: 5px 5px var(--primary);
};

@media only screen and (max-width: 600px) {
    .container {
        flex-direction: column;
    }

    .middle {
        width: 85%;
    }
}