@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    line-height: 23px;
}

html{scroll-behavior:smooth}

:root {
    --primary: #F85772;
    --gray: rgb(51, 51, 51);
    --light-primary: #FFF2F4;
    --border: rgb(207, 207, 207);
}