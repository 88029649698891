.section {
    min-height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 20px;
}

.middle {
    max-width: 600px;
    text-align: center;
}

.middle h1 {
    font-size: 2.5rem;
    font-weight: 600;
}

.middle p {
    color: var(--gray);
    font-size: 1rem;
    line-height: 23px;
}

.buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    padding: 10px;
    text-decoration: none;
    font-weight: 600;
    color: white;
    background-color: var(--primary);
    border-radius: 5px;
    transition: 0.1s;
}

.buttons a:nth-child(2) {
    background-color: white;
    color: var(--primary);
    border: 2px solid var(--primary);
}


.buttons a:nth-child(2):hover {
    box-shadow: 5px 5px var(--primary);
}
.buttons a:hover {
    box-shadow: 5px 5px var(--border);
}

.special {
    text-align: center;
    color: var(--primary);
}