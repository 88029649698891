.blob {
    border-radius: 100px;
    filter: blur(60px);
    z-index: -10000000000;
}

.yellow {
    background-color: #EDB74D;
    position: absolute;
    top: 200px;
    right: 0px;
    height: 200px;
    width: 200px;

    animation: yelloww 8s infinite ease;
}

.green {
    background-color: #6FB18A;
    position: absolute;
    top: 80px;
    right: 0;
    height: 200px;
    width: 250px;

    animation: greenn 8s infinite ease;
}

.red {
    background-color: #EB6666;
    position: absolute;
    right: 0;
    top: 300px;
    height: 250px;
    width: 200px;

    animation: redd 8s infinite linear;
}

@keyframes yelloww {
    0% {
        top: 200px;
        left: 100px;
        transform: scale(1);
    }

    30% {
        top: 300px;
        left: 150px;
        transform: scale(1.2);
    }

    60% {
        top: 100px;
        left: 200px;
        transform: scale(1.3);
    }

    100% {
        top: 200px;
        left: 100px;
        transform: scale(1);
    }
}

@keyframes greenn {
    0% {
        top: 20px;
        right: -20px;
        transform: scale(1.2);
    }

    30% {
        top: 100px;
        right: -20px;
        transform: scale(1);
    }

    60% {
        top: 50px;
        right: 100px;
        transform: scale(1);
    }

    100% {
        top: 20px;
        right: -20px;
        transform: scale(1.2);
    }
}

@keyframes redd {
    0% {
        top: 250px;
        right: 0px;
        transform: scale(1);
    }

    30% {
        top: 150px;
        right: 150px;
        transform: scale(1.4);
    }

    60% {
        top: 250px;
        right: 100px;
        transform: scale(1);
    }

    100% {
        top: 250px;
        right: 0px;
        transform: scale(1);
    }
}


@media only screen and (max-width: 600px) {
    .blob {
        opacity: 0.3;
    }
}