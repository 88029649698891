.section {
    min-height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.middle {
    width: 80%;
    height: 100%;
}

.middle h1 {
    font-weight: 600;
}

.target {
    width: 100%;
    color: var(--gray);
    display: flex;
    align-items: center;
    gap: 20px;
    overflow-x: scroll;

    text-wrap: nowrap;
    cursor: pointer;
}

.target::-webkit-scrollbar {
    display: none;
}

.target div {
    font-weight: 600;
    
    color: var(--gray);
    padding: 15px 20px;
    border-radius: 40px;
    text-wrap: nowrap;
}

.divider1 h1 {
    font-weight: 600;
    font-size: 1.8rem;
}

.divider1 {
    flex: 1;
}

.divider2 {
    flex: 1.5;
}

.line {
    height: 1px;
    width: 100%;
    background-color: var(--border);
    margin-top: 60px;
    margin-bottom: 30px;
}

.line2 {
    height: 1px;
    width: 100%;
    background-color: var(--border);
    margin-bottom: 30px;
}

.wrapper2 {
    width: 100%;
    display: flex;
}

.singleCard {
    border-top: 1px solid var(--border);
    padding-block: 30px;
}

.singleCard:first-child {
    border-top: none;
}

.singleCard h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: var(--primary);
}

.middle iframe {
    width: 100%;
    height: 500px;
}


@media only screen and (max-width: 600px) {
    .wrapper2 {
        flex-direction: column;
    }

    .middle {
        width: 85%;
    }

}